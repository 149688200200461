<template>
  <div class="Pin_Change">
    <div class="Pin_Change_box">
      <h2 class="Pin_Change_h2">
        投稿ピンを変更
      </h2>

      <p class="Pin_Change_error">
        {{ Validation }}
      </p>

      <form class="Login__form" @submit.prevent="onSubmit">
        <input
          ref="focus_postid"
          v-model="postid"
          type="text"
          class="Input"
          placeholder="投稿ID"
        />

        <v-btn
          class="Button"
          type="submit"
          color="red darken-4"
          :loading="loading"
          :disabled="loading"
          @click="checkFrom"
        >
          ピンを変更
        </v-btn>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      Postdata: "",
      Validation: "",
      loading: false,
    };
  },
  methods: {
    checkFrom: function(event) {
      this.loading = true;

      // 初期化
      this.Validation = "";

      // フォームチェック
      var validPostid = new Promise((resolve, reject) => {
        if (!this.postid) {
          this.Validation = "投稿IDを記入してください";
          this.$refs.focus_postid.focus();
        } else {
          this.axios
            .get(
              "https://cors.bexix.net/https://api.yay.space/v2/posts/" +
                this.postid
            )
            .then((response) => {
              this.Postdata = response.data.post;
              resolve();
            })
            .catch((error) => {
              if (error.response.data.message === "not found") {
                this.Validation = "投稿が存在しません";
              } else {
                this.Validation =
                  "原因不明のエラーが発生したため、しばらく経ってからやり直してください。";
              }
              reject();
            });
        }
      });

      validPostid
        .then(() => {
          const config = {
            headers: {
              authorization: "Bearer " + this.$store.getters.userToken,
            },
          };
          const params = {
            post_id: this.postid,
            _post: this.Postdata,
          };

          var Promise1 = new Promise((resolve) => {
            this.axios
              .put(
                "https://cors.bexix.net/https://api.yay.space/v2/posts/pinned_post",
                params,
                config
              )
              .then(() => {
                this.Validation = "変更が完了しました！";
              })
              .catch(() => {
                this.Validation =
                  "原因不明のエラーが発生したため、しばらく経ってからやり直してください。";
              });
            resolve();
          });
          Promise1.then(() => {
            this.loading = false;
          });
        })
        .catch(() => {
          this.loading = false;
        });
      event.preventDefault();
    },
  },
};
</script>

<style>
.Pin_Change {
  margin: 0 auto;
  text-align: center;
}

.Pin_Change_box {
  margin-top: 50px;
}

.Login__form {
  margin-top: 30px;
}
</style>
